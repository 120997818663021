import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
const ProducersEdit: FC = () => {
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Edit Producer Enrollment Information</span>
              Producer's Name 
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
            <ul className="d-flex flex-wrap p-0 viewList">
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">State/Territory</span>
                  <input type="text" className="loginInput" defaultValue="Char" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">County</span>
                  <input type="text" className="loginInput" defaultValue="Num" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Producer Name</span>
                  <input type="text" className="loginInput" defaultValue="Char" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Change Future Data for Previously Enrolled</span>
                  <input type="text" className="loginInput" defaultValue="Num" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Producer Start Date</span>
                  <input type="text" className="loginInput" defaultValue="mm-dd-yy" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Organic Farm</span>
                  <input type="text" className="loginInput" defaultValue="Char" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Organic Field</span>
                  <input type="text" className="loginInput" defaultValue="Char" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">CSAF Experience</span>
                  <input type="text" className="loginInput" defaultValue="mm-dd-yy" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Total Forest Area</span>
                  <input type="text" className="loginInput" defaultValue="num" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Total Livestock Area</span>
                  <input type="text" className="loginInput" defaultValue="num" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Livestock Type</span>
                  <input type="text" className="loginInput" defaultValue="Char" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Livestock Head</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
            </ul>
            <div className="pt-5 text-end">
              <NavLink to="/producers/view">
                <button type="button" className="formBtn cancel">
                  Cancel
                </button>
              </NavLink>
              <NavLink to="/producers">
                <button type="button" className="ms-3 formBtn">
                Save Details
                </button>
              </NavLink>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ProducersEdit;
