import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
const ProjectGrantee: FC = () => {
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Project Grantee</span>
            </div>
            <div className="bradcrumb">
            <NavLink  to="/dashboard">Dashboard</NavLink> &gt; Project Grantee
            </div>
          </div>
        </section>
        <section className="filterCon">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center pt-3 pb-3">
              <div className="searchBy">Search By</div>
              <ul className="d-flex flex-wrap filterBox">
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Project ID</span>
                    <input
                      type="text"
                      className="filterInput"
                      placeholder="1002"
                    />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Grantee Organization Name</span>
                    <input type="text" className="filterInput" placeholder="Grantee Organization Name" />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Primary Point of Contact</span>
                    <input type="text" className="filterInput" placeholder="+1 23 546 7859" />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Primary Point of Contact Email </span>
                    <input type="text" className="filterInput" placeholder="example@mail.com" />
                  </div>
                </li>
                <li className="btn"><button type="button"><span className="material-icons">search</span></button></li>
              </ul>
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 mt-2 whtBg">
          <div className="container">
            <div className="tableBox">
              <table>
                <tr>
                  <th>Project ID</th>
                  <th>Grantee Organization Name</th>
                  <th>Primary Point of Contact</th>
                  <th>Primary Point of Contact Email</th>
                  <th>Project Start Date</th>
                  <th>Project End Date</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>NC_C_1</td>
                  <td>Carter Farms</td>
                  <td>+1 000 000 0000</td>
                  <td>voredeg216@kindomd.com</td>
                  <td>Feb 10 2023</td>
                  <td>Feb 10 2024</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/project-grantee/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/project-grantee/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>VA_O_2</td>
                  <td>B.R. Jeffers Farms LLC</td>
                  <td>+1 000 000 0000</td>
                  <td>99experts.com</td>
                  <td>Feb 10 2023</td>
                  <td>Feb 10 2024</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/project-grantee/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/project-grantee/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>SC_O_1</td>
                  <td>Bio Way Farm</td>
                  <td>+1 000 000 0000</td>
                  <td>abbeyroadlondon.co.uk</td>
                  <td>Feb 10 2023</td>
                  <td>Feb 10 2024</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/project-grantee/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/project-grantee/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>GA_O_1</td>
                  <td>Blue Heron Urban Farms...</td>
                  <td>+1 000 000 0000</td>
                  <td>abolition-now.com</td>
                  <td>Feb 10 2023</td>
                  <td>Feb 10 2024</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/project-grantee/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/project-grantee/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>GA_C_1</td>
                  <td>Bramblett Angus</td>
                  <td>+1 000 000 0000</td>
                  <td>baptistmail.com</td>
                  <td>Feb 10 2023</td>
                  <td>Feb 10 2024</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/project-grantee/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/project-grantee/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>NC_C_2</td>
                  <td>Country Pickin' Farms</td>
                  <td>+1 000 000 0000</td>
                  <td>boarderzone.com</td>
                  <td>Feb 10 2023</td>
                  <td>Feb 10 2024</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/project-grantee/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/project-grantee/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>NC_C_3</td>
                  <td>Davis Farms Shady Creek, LLC</td>
                  <td>+1 000 000 0000</td>
                  <td>canwetalk.com</td>
                  <td>Feb 10 2023</td>
                  <td>Feb 10 2024</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/project-grantee/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/project-grantee/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>NC_O_1</td>
                  <td>Guilford College Farm</td>
                  <td>+1 000 000 0000</td>
                  <td>city-of-cambridge.com</td>
                  <td>Feb 10 2023</td>
                  <td>Feb 10 2024</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/project-grantee/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/project-grantee/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>SC_C_2</td>
                  <td>H and G Produce</td>
                  <td>+1 000 000 0000</td>
                  <td>dnsmadeeasy.com</td>
                  <td>Feb 10 2023</td>
                  <td>Feb 10 2024</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/project-grantee/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/project-grantee/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>GA_O_2</td>
                  <td>Hearts of Harvest Farm</td>
                  <td>+1 000 000 0000</td>
                  <td>from-germany.net</td>
                  <td>Feb 10 2023</td>
                  <td>Feb 10 2024</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/project-grantee/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/project-grantee/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
              <div className="totalResul">Result found (15)</div>
              <div className="paging">
                <a href="#">&lt;&lt;</a>
                <a href="#">&lt;</a>
                <a href="#" className="number">1</a>
                <a href="#">&gt;</a>
                <a href="#">&gt;&gt;</a>
              </div>
              <div></div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectGrantee;
