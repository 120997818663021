import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import './assets/css/common.scss';
import { Provider } from 'react-redux';
import { Notify } from './context';
import store from './redux/store';
import { RootRoutes } from './routers';
import { PermissionsProvider } from './context/PermissionsContext';

function App() {
  return (
    <Provider store={store}>
      <PermissionsProvider>
      <Notify>
        <RootRoutes />
      </Notify>
      </PermissionsProvider>
    </Provider>
  );
}

export default App;