export const hasPermission = (
  permissions: Record<string, any>,
  role: string,
  module: string,
  action: 'add' | 'edit' | 'delete' | 'view'
): boolean => {
  const rolePermissions = permissions[role];
  if (!rolePermissions) return false;

  const modulePermissions = rolePermissions[module];
  if (!modulePermissions) return false;

  return modulePermissions[`can${action.charAt(0).toUpperCase() + action.slice(1)}`] === true;
};

  