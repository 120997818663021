//   import { dataApiSuccess } from "../../components/messages";
import API from "../../config/api";
import { Success } from "../../context/notify";
import { BlogDetailsParams, BlogParams } from "../../Typings/dataListing";
import { dataActionTypes } from "../constants/ActionTypes";


export const fetchStateData = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = "/api/State/GetStateList"
    await API().get(path, {
        params: payload,
    }).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};

export const fetchStatusData = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = "/api/Status/GetStatusList"
    await API().get(path, {
        params: payload,
    }).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};

export const fetchPermissions = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = "api/Permission/GetPermissionByRole"
    await API().get(path, {
        params: payload,
    }).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};

export const fetchSubCategoryList = (defaultparams: any) => async (dispatch: any) => {
    const response = await API().post('getSubCategory',defaultparams);
    dispatch({ type: dataActionTypes.FATCH_SUBCATEGORY_LIST, payload: response.data });
}

export const fetchPageInfo = (defaultparams: any) => async (dispatch: any) => {
    const response = await API().post('getPagesInfo', defaultparams);
    dispatch({ type: dataActionTypes.FATCH_PAGE_INFO, payload: response.data });
}

export const fetchFAQList = () => async (dispatch: any) => {
    const response = await API().get('getFAQList');
    dispatch({ type: dataActionTypes.FATCH_FAQ_LIST, payload: response.data });
}

export const fetchBlogList = (defaultparams: BlogParams, isReset: boolean = false) => async (dispatch: any) => {
    const response = await API("", false, false, !isReset).post('getBlogList', defaultparams);
    dispatch({ type: dataActionTypes.FATCH_BLOG_LIST, payload: response.data, isReset: isReset })
}

export const featchBlogInfo = (defaultparams: BlogDetailsParams, isReset: boolean = false) => async (dispatch: any) => {
    const response = await API().post('getBlogInfo', defaultparams);
    dispatch({ type: dataActionTypes.FATCH_BLOG_INFO, payload: response, isReset: isReset })
}

export const fetchTestimonialsList = () => async (dispatch: any) => {
    const response = await API().get('getTestimonialsList');
    dispatch({ type: dataActionTypes.FATCH_TESTIMONIALS_LIST, payload: response.data })
} 

export const fetchWebSettings = () => async (dispatch: any) => {
    const response = await API().get('getWebSettings');
    dispatch({ type: dataActionTypes.FATCH_SETTINGS_LIST, payload: response.data })
}
//export const addContactInformation = (contactInfo: any, callBack?: any) => async (dispatch: any) => {
//    const response = await API().post('addContactInfo', contactInfo);
//    if (response?.status === 200) {
//        Success(dataApiSuccess.contactAdded);
//        if (callBack) {
//            callBack(); 
//        }
//        dispatch({ type: dataActionTypes.ADD_CONTACT_INFO, payload: response.data })
//    }
//}