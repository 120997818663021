import React from "react";

interface PaginationProps {
  PageIndex: number;

  TotalPages: number;

  GoToPage: (page: number) => void;

  hasPreviousPage: boolean;

  hasNextPage: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  PageIndex,

  TotalPages,

  GoToPage,

  hasPreviousPage,

  hasNextPage,
}) => {
  const handleFirstPage = () => {
    if (PageIndex !== 1) GoToPage(1);
  };

  const handlePreviousPage = () => {
    if (hasPreviousPage) GoToPage(PageIndex - 1);
  };

  const handleNextPage = () => {
    if (hasNextPage) GoToPage(PageIndex + 1);
  };

  const handleLastPage = () => {
    if (PageIndex !== TotalPages) GoToPage(Number(TotalPages));
  };

  return (
    <div className="paging">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();

          handleFirstPage();
        }}
        className={PageIndex == 1 ? "disabled" : ""}
      >
        &lt;&lt;
      </a>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();

          handlePreviousPage();
        }}
        className={!hasPreviousPage ? "disabled" : ""}
      >
        &lt;
      </a>
      <a href="#" className="number">
        {PageIndex == 0 ? "1" : PageIndex}
      </a>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();

          handleNextPage();
        }}
        className={!hasNextPage ? "disabled" : ""}
      >
        &gt;
      </a>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();

          handleLastPage();
        }}
        className={PageIndex == TotalPages ? "disabled" : ""}
      >
        &gt;&gt;
      </a>
    </div>
  );
};

export default Pagination;
