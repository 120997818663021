import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
const PartnerEdit: FC = () => {
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Edit Partner Activity Information</span>
              Name of Partner
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
            <ul className="d-flex flex-wrap p-0 viewList">
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Partner ID</span>
                  <input type="text" className="loginInput" defaultValue="0001" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Partner Name</span>
                  <input type="text" className="loginInput" defaultValue="Company Name" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Partner Type</span>
                  <input type="text" className="loginInput" defaultValue="Type of Partner" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Partner POC</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Partner POC Email</span>
                  <input type="text" className="loginInput" defaultValue="exmple@email.com" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Partnership Start Date</span>
                  <input type="text" className="loginInput" defaultValue="22 Nov 2024" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Partnership End Date</span>
                  <input type="text" className="loginInput" defaultValue="21 Nov 2025" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">New Partnership</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Partner Total Expenses</span>
                  <input type="text" className="loginInput" defaultValue="5" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Total Federal Incentives</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Total Match Contribution</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Total Match Incentives</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Match Type</span>
                  <input type="text" className="loginInput" defaultValue="White" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Match Amount </span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Training Provided</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Activity By Partner</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Activity Cost</span>
                  <input type="text" className="loginInput" defaultValue="No" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Products Supplied</span>
                  <input type="text" className="loginInput" defaultValue="No" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Product Source</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
            </ul>
            <div className="pt-5 text-end">
              <NavLink to="/partner/view">
                <button type="button" className="formBtn cancel">
                  Cancel
                </button>
              </NavLink>
              <NavLink to="/partner">
                <button type="button" className="ms-3 formBtn">
                  Save
                </button>
              </NavLink>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default PartnerEdit;
