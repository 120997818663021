import { FC, useEffect, useRef, useState } from "react";
import Logo from "../../assets/img/logo.png";
import { loginInfo } from "../../Typings/accountListing";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { formFields } from "../../components/messages";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { errorMsgs } from "../../context/notify";
import { submitLoginData } from "../../redux/actions/accountAction";
import { useNavigate } from "react-router-dom";

const Login: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  //useEffect;
  const formik: FormikProps<loginInfo> = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(formFields.email),
      password: Yup.string().required(formFields.password),
    }),
    onSubmit: (values, { setSubmitting, setErrors }) => {
      dispatch(
        submitLoginData(values, (response: any) => {
          // console.log(response);
          formik.resetForm();
          try {
            localStorage.clear();
            // console.log(response);
            if (response.statusCode === 0) {
              errorMsgs(response.message);
            } else if (response.statusCode === 1) {
              localStorage.setItem("accessToken", response.data.accessToken);
              localStorage.setItem(
                "user_Id",
                response.data.userDetails.user_id
              );
              localStorage.setItem(
                "username",
                response.data.userDetails.username
              );
              localStorage.setItem(
                "roleid",
                response.data.userDetails.roleid
              );
              navigate("/dashboard");
            }
          } catch (error) {
          } finally {
            setSubmitting(false);
          }
        })
      );
    },
  });

  return (
    <section className="d-flex">
      <div className="loginDet d-flex align-items-center">
        <div className="loginInner">
          <div className="mb-4">
            <img src={Logo} alt="" />
          </div>
          <div className="loginHd mb-4 mb-xl-5">
            <span className="d-block">Welcome To Climate-Smart</span>
            Led by Rodale Institute and funded by the USDA
          </div>
          <div className="loginBox">
            <div className="p-4 p-xl-5">
              <div className="hd pb-4">Sign In</div>
              <form onSubmit={formik.handleSubmit}>
                <ul className="loginForm p-0">
                  <li>
                    <div className="position-relative">
                      <span className="field">Email</span>
                      <input
                        type="text"
                        className="loginInput"
                        placeholder="Enter Email Here"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </li>
                  <li>
                    <div className="position-relative">
                      <span className="field">Password</span>
                      <input
                        type="password"
                        className="loginInput"
                        placeholder="Enter Password Here"
                        {...formik.getFieldProps("password")}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="error">{formik.errors.password}</div>
                      ) : null}
                    </div>
                  </li>
                  <li className="d-flex justify-content-between align-items-center">
                    <a href="#" className="fp">
                      Forgot Password?
                    </a>
                    <button
                      type="submit"
                      className="loginBtn position-relative"
                      disabled={!formik.isValid || formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <div className="loader"></div>
                      ) : (
                        "Sign In"
                      )}
                    </button>
                  </li>
                </ul>
              </form>
            </div>
            <div className="p-3 text-center newuser">
              New User? <a href="#">Create Account</a>
            </div>
          </div>
        </div>
      </div>
      <div className="loginBg"></div>
    </section>
  );
};

export default Login;
