import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
const PartnerView: FC = () => {
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Partner Activity Details</span> Name of Partner
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
           <ul className="d-flex flex-wrap p-0 viewList">
            <li className="col-6 col-md-4 col-lg-3">Partner ID <span className="d-block pt-1">1001</span></li>
            <li className="col-6 col-md-4 col-lg-3">Partner Name <span className="d-block pt-1">Carolina Farm Stewardship</span></li>
            <li className="col-6 col-md-4 col-lg-3">Partner Type <span className="d-block pt-1">Type of Partner</span></li>
            <li className="col-6 col-md-4 col-lg-3">Partner POC <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Partner POC Email <span className="d-block pt-1">exmple@email.com</span></li>
            <li className="col-6 col-md-4 col-lg-3">Partnership Start Date <span className="d-block pt-1">22 Nov 2024</span></li>
            <li className="col-6 col-md-4 col-lg-3">Partnership End Date <span className="d-block pt-1">21 Nov 2025</span></li>
            <li className="col-6 col-md-4 col-lg-3">New Partnership <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Partner Total Expenses <span className="d-block pt-1">5</span></li>
            <li className="col-6 col-md-4 col-lg-3">Total Federal Incentives  <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Total Match Contribution  <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Total Match Incentives  <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Match Type <span className="d-block pt-1">White</span></li>
            <li className="col-6 col-md-4 col-lg-3">Match Amount  <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Training Provided <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Activity By Partner <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Activity Cost <span className="d-block pt-1">No</span></li>
            <li className="col-6 col-md-4 col-lg-3">Products Supplied <span className="d-block pt-1">No</span></li>
            <li className="col-6 col-md-4 col-lg-3">Product Source <span className="d-block pt-1">N/A</span></li>
           </ul>
           <div className="pt-5 text-end">
            <NavLink  to="/partner"><button type="button" className="formBtn cancel">Cancel</button></NavLink>
            <NavLink  to="/partner/edit"><button type="button" className="ms-3 formBtn">Edit Details</button></NavLink>
           </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default PartnerView;
