import React from "react";

interface YesNoDropdownProps {
  name: string;
  value: boolean | null; 
  onChange: (name: string, value: boolean) => void; 
}

const YesNoDropdown: React.FC<YesNoDropdownProps> = ({ name, value, onChange }) => {
  return (
    <select
      name={name}
      value={value === null ? "" : value ? "Yes" : "No"}
      onChange={(e) => onChange(name, e.target.value === "Yes")}
      className="loginInput"
    >
      <option value="" disabled>
        Select
      </option>
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  );
};

export default YesNoDropdown;
