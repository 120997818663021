import { FC, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from '../../assets/img/logo.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { string } from "yup";

const Header: FC = () => {
    function getInitials(fullName: string): string {
        const nameParts = fullName.split(" ");
        const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join("");
        return initials;
      }
    
    const navigate = useNavigate();
    const username = localStorage.getItem('username') || "";
    const initials = getInitials(username);
    const handleClick = () => {
        navigate('/login');
    };
    return (
       <>
            <header className="header">
                <div className="container d-flex align-items-center justify-content-between">
                <div className="logo"><NavLink  to="/dashboard"><img alt="" src={Logo} /></NavLink></div>
                    <div>
                    <Dropdown align="end" className="acLink">
                        <Dropdown.Toggle className="d-flex align-items-center"><span className="icon d-inline-flex align-items-center justify-content-center">{initials}</span>{username}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#">{username}</Dropdown.Item>
                            <Dropdown.Item href="/login">Sign Out</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </header>
       </>
    );
}

export default Header;