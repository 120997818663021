import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchPermissions } from "../redux/actions/dataActions";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";

interface PermissionsContextType {
  permissions: Record<string, any>;
  loading: boolean;
  error: string | null;
}

interface Props {
  children: React.ReactNode;
}

const PermissionsContext = createContext<PermissionsContextType | undefined>(
  undefined
);

export const PermissionsProvider: React.FC<Props> = ({ children }) => {
  const [permissions, setPermissions] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const payload = {
        roleid: localStorage.getItem("roleid")
    };
    const currentUrl = window.location.pathname;
    const handleNext = (response: any) => {
      if (response?.data) {
        const processedPermissions = processPermissions(response.data || []);
        setPermissions(processedPermissions);
      } else {
        console.error('Failed to fetch permissions:', response);
      }
    };
    if(currentUrl !== "/Login" && currentUrl !== "/"){
      dispatch(fetchPermissions(payload, handleNext));
    }
  }, [dispatch]);

  const processPermissions = (data: any[]) => {
    const result: Record<string, any> = {};

    data.forEach((item) => {
      const roleId = item.roleid;
      const moduleName = item.modulename || "unknown";

      if (!result[roleId]) {
        result[roleId] = {};
      }

      result[roleId][moduleName] = {
        canAdd: item.canadd,
        canEdit: item.canedit,
        canDelete: item.candelete,
        canView: item.canview,
      };
    });

    return result;
  };

  return (
    <PermissionsContext.Provider value={{ permissions, loading, error }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionsProvider");
  }
  return context;
};
