import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import * as Yup from "yup";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { string, object, date, ref, boolean } from "yup";
import { AddFarmProfileData } from "../../redux/actions/farmProfileAction";
import { FormikProps, useFormik } from "formik";
import { AddUpdateFarmProfile } from "../../Typings/dataListing";
import { errorMsgs } from "../../context/notify";
import YesNoDropdown from "../../components/YesOrNoDropDown";
import DropDownForm from "../../components/DropDownForm";
import {
  equipmentTypeOptions,
  genderOptions,
  plantingZoneOptions,
  raceEthnicityOptions,
  typeOfOperationOptions,
} from "../../Typings/constDropDownValue";

const FarmsProfileAdd: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { FarmId } = useParams();
  const [Gender, SetGender] = useState<string>("");
  const [PlantingZone, SetPlantingZone] = useState<string>("");
  const [OperationType, SetOperationType] = useState<string>("");
  const [Race, SetRace] = useState<string>("");
  const navigate = useNavigate();

  const [selectedZone, setSelectedZone] = useState("");
  const handleZoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedZone(event.target.value);
    SetPlantingZone(event.target.value);
  };

  const [selectedRaceEthnicity, setSelectedRaceEthnicity] = useState("");
  const handleRaceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRaceEthnicity(event.target.value);
    SetRace(event.target.value);
  };

  const [selectedOperationType, setSelectedOperationType] =
    useState<string>("");
  const handleOperationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedOperationType(event.target.value);
    SetOperationType(event.target.value);
  };
  const [selectedGender, setSelectedGender] = useState<string>("");

  const handleGenderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGender(event.target.value);
    SetGender(event.target.value);
  };
  const formik: FormikProps<AddUpdateFarmProfile> = useFormik({
    initialValues: {
      farm_id: "",
      usda_fsa_num: 0,
      acreage: "",
      planting_zone: PlantingZone,
      tract: "",
      field_numbers: "",
      commodities_grown: "",
      type_of_operation: OperationType,
      years_in_production: 0,
      beginning_farmer: null as boolean | null,
      limited_resource_farmer: null as boolean | null,
      veteran_or_active_duty_service_member: null as boolean | null,
      race_ethnicity: "",
      self_describe_race_ethnicity: Race,
      gender: Gender,
      self_describe_gender: "",
      use_cover_crops: null as boolean | null,
      enrolled_in_other_nrcs_program: null as boolean | null,
      other_nrcs_programs: "",
      willing_to_dedicate_10_acres: null as boolean | null,
      reason_for_not_willing_to_dedicate_10_acres: "",
      willing_to_commit_enrolled_acreage_until_spring_2028: null as
        | boolean
        | null,
      reason_not_willing_to_commit_enrolled_acreage_until_spring_2028: "",
      willing_to_provide_periodic_economic_data: null as boolean | null,
      reason_for_not_willing_to_provide_periodic_economic_data: "",
      willing_to_allow_scheduled_visits: null as boolean | null,
      comments: "",
      nrcs_1270: null as boolean | null,
      w_9: null as boolean | null,
      fsa_waiver: null as boolean | null,
      equipment_survey: null as boolean | null,
      cash_crop_survey: null as boolean | null,
      enrolled_acreage: 0,
      equipment_type: "",
      tractor_hp: "",
      three_pt_hitch_category: "",
      mower_bush_hog: null as boolean | null,
      tiller: null as boolean | null,
      tractor_hydraulic: null as boolean | null,
      tractor_electric: null as boolean | null,
      seed_drill: null as boolean | null,
      mulch_layer: null as boolean | null,
      transplanter: null as boolean | null,
      vehicle_to_flatbed_trailer: null as boolean | null,
      type_of_truck: "",
      how_unload_equipment_from_trailer: "",
    },
    validationSchema: object({
      farm_id: Yup.string().required("Farm Id is required"),
      type_of_operation: Yup.string()
        .required("Operation Type is required")
        .oneOf(
          [
            "Conventional",
            "Certified Organic",
            "Naturally Grown",
            "Certified Naturally Grown",
          ],
          "Invalid operation type selected"
        ),
    }),
    onSubmit: (values, { setSubmitting, setErrors }) => {
      console.log(values);
      dispatch(
        AddFarmProfileData(values, (response: any) => {
          formik.resetForm();
          try {
            if (response.statusCode === 0) {
              errorMsgs(response.message);
            } else if (response.statusCode === 1) {
              navigate("/farms-profile");
            } else if (response.statusCode === 2) {
              navigate("/farms-profile");
            }
          } catch (error) {
          } finally {
            setSubmitting(false);
          }
        })
      );
    },
  });
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Edit Carter Farms Information</span>
              {formik.values.farm_id}
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
            <form onSubmit={formik.handleSubmit}>
              <ul className="d-flex flex-wrap p-0 viewList">
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Farm ID</span>
                    <input
                      type="text"
                      name="farm_id"
                      value={formik.values.farm_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                    {formik.touched.farm_id && formik.errors.farm_id && (
                      <div className="error">{formik.errors.farm_id}</div>
                    )}
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">USDA / FSA</span>
                    <input
                      type="number"
                      name="usda_fsa_num"
                      value={formik.values.usda_fsa_num}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Acreage</span>
                    <input
                      type="text"
                      name="acreage"
                      value={formik.values.acreage}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Planting Zone</span>
                    <DropDownForm
                      options={plantingZoneOptions}
                      value={formik.values.planting_zone}
                      onChange={(event) =>
                        formik.setFieldValue(
                          "planting_zone",
                          event.target.value
                        )
                      }
                      className="filterInput"
                      Title="Select Planting Zone"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Tract</span>
                    <input
                      type="text"
                      name="tract"
                      value={formik.values.tract}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Field Numbers</span>
                    <input
                      type="text"
                      name="field_numbers"
                      value={formik.values.field_numbers}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Commodities Grown</span>
                    <textarea
                      className="loginInput"
                      name="commodities_grown"
                      value={formik.values.commodities_grown}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Type Of Operation</span>
                    <DropDownForm
                      options={typeOfOperationOptions}
                      value={formik.values.type_of_operation}
                      onChange={(event) =>
                        formik.setFieldValue(
                          "type_of_operation",
                          event.target.value
                        )
                      }
                      className="filterInput"
                      Title="Select Operation Type"
                    />
                    {formik.touched.type_of_operation &&
                      formik.errors.type_of_operation && (
                        <div className="error">
                          {formik.errors.type_of_operation}
                        </div>
                      )}
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Years In Production</span>
                    <input
                      type="number"
                      name="years_in_production"
                      value={formik.values.years_in_production}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Beginning Farmer</span>
                    <YesNoDropdown
                      name="beginning_farmer"
                      value={formik.values.beginning_farmer}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Limited Resource Farmer</span>
                    <YesNoDropdown
                      name="limited_resource_farmer"
                      value={formik.values.limited_resource_farmer}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">
                      Duty Service Member (Veteran / Active)
                    </span>
                    <YesNoDropdown
                      name="beginning_farmer"
                      value={
                        formik.values.veteran_or_active_duty_service_member
                      }
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Race / Ethnicity</span>
                    <DropDownForm
                      options={raceEthnicityOptions}
                      value={formik.values.race_ethnicity}
                      onChange={(event) =>
                        formik.setFieldValue(
                          "race_ethnicity",
                          event.target.value
                        )
                      }
                      className="filterInput"
                      Title="Select Race / Ethnicity"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Self Describe Race Ethnicity</span>
                    <input
                      type="text"
                      name="self_describe_race_ethnicity"
                      value={formik.values.self_describe_race_ethnicity}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Gender</span>
                    <DropDownForm
                      options={genderOptions}
                      value={formik.values.gender}
                      onChange={(event) =>
                        formik.setFieldValue("gender", event.target.value)
                      }
                      className="filterInput"
                      Title="Select Gender"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Self Describe Gender</span>
                    <input
                      type="text"
                      name="self_describe_gender"
                      value={formik.values.self_describe_gender}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Use Cover Crops</span>
                    <YesNoDropdown
                      name="use_cover_crops"
                      value={formik.values.use_cover_crops}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">
                      Enrolled In Other Nrcs Program{" "}
                    </span>
                    <YesNoDropdown
                      name="enrolled_in_other_nrcs_program"
                      value={formik.values.enrolled_in_other_nrcs_program}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Other Nrcs Programs</span>
                    <input
                      type="text"
                      name="other_nrcs_programs"
                      value={formik.values.other_nrcs_programs}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Willing To Dedicate 10 Acres</span>
                    <YesNoDropdown
                      name="willing_to_dedicate_10_acres"
                      value={formik.values.willing_to_dedicate_10_acres}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">
                      Reason Not Willing Dedicate 10 Acres
                    </span>
                    <input
                      type="text"
                      name="reason_for_not_willing_to_dedicate_10_acres"
                      value={
                        formik.values
                          .reason_for_not_willing_to_dedicate_10_acres
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">
                      Willing Commit Enrolled Acreage (Spring 2028)
                    </span>
                    <YesNoDropdown
                      name="willing_to_commit_enrolled_acreage_until_spring_2028"
                      value={
                        formik.values
                          .willing_to_commit_enrolled_acreage_until_spring_2028
                      }
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">
                      Reason Not Willing Commit Enrolled Acreage (Spring 2028)
                    </span>
                    <input
                      type="text"
                      name="reason_not_willing_to_commit_enrolled_acreage_until_spring_2028"
                      value={
                        formik.values
                          .reason_not_willing_to_commit_enrolled_acreage_until_spring_2028
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">
                      Willing Provide Periodic Economic Data
                    </span>
                    <YesNoDropdown
                      name="willing_to_provide_periodic_economic_data"
                      value={
                        formik.values.willing_to_provide_periodic_economic_data
                      }
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">
                      Reason Not Willing Provide Periodic Economic Data
                    </span>
                    <input
                      type="text"
                      name="reason_for_not_willing_to_provide_periodic_economic_data"
                      value={
                        formik.values
                          .reason_for_not_willing_to_provide_periodic_economic_data
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">
                      Willing Allow Scheduled Visits
                    </span>
                    <YesNoDropdown
                      name="willing_to_allow_scheduled_visits"
                      value={formik.values.willing_to_allow_scheduled_visits}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">
                      Reason Not Willing Provide Periodic Economic Data
                    </span>
                    <input
                      type="text"
                      name="reason_for_not_willing_to_provide_periodic_economic_data"
                      value={
                        formik.values
                          .reason_for_not_willing_to_provide_periodic_economic_data
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">
                      Willing Allow Scheduled Visits
                    </span>
                    <YesNoDropdown
                      name="willing_to_allow_scheduled_visits"
                      value={formik.values.willing_to_allow_scheduled_visits}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Nrcs 1270</span>
                    <YesNoDropdown
                      name="nrcs_1270"
                      value={formik.values.nrcs_1270}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">W_9</span>
                    <YesNoDropdown
                      name="w_9"
                      value={formik.values.w_9}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">FSA Waiver</span>
                    <YesNoDropdown
                      name="fsa_waiver"
                      value={formik.values.fsa_waiver}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Equipment Survey</span>
                    <YesNoDropdown
                      name="equipment_survey"
                      value={formik.values.equipment_survey}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Cash Crop Survey</span>
                    <YesNoDropdown
                      name="cash_crop_survey"
                      value={formik.values.cash_crop_survey}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Enrolled Acreage</span>
                    <input
                      type="number"
                      name="enrolled_acreage"
                      value={formik.values.enrolled_acreage}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Equipment Type</span>
                    <DropDownForm
                      options={equipmentTypeOptions}
                      value={formik.values.equipment_type}
                      onChange={(event) =>
                        formik.setFieldValue(
                          "equipment_type",
                          event.target.value
                        )
                      }
                      className="filterInput"
                      Title="Select Equipment Type"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Tractor hp</span>
                    <input
                      type="text"
                      name="tractor_hp"
                      value={formik.values.tractor_hp}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">3 pt Hitch Category</span>
                    <input
                      type="text"
                      name="three_pt_hitch_category"
                      value={formik.values.three_pt_hitch_category}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Mower Bush Hog</span>
                    <YesNoDropdown
                      name="mower_bush_hog"
                      value={formik.values.mower_bush_hog}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Tiller</span>
                    <YesNoDropdown
                      name="tiller"
                      value={formik.values.tiller}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Tractor Hydraulic</span>
                    <YesNoDropdown
                      name="tractor_hydraulic"
                      value={formik.values.tractor_hydraulic}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Tractor Electric</span>
                    <YesNoDropdown
                      name="tractor_electric"
                      value={formik.values.tractor_electric}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Seed Drill</span>
                    <YesNoDropdown
                      name="seed_drill"
                      value={formik.values.seed_drill}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Mulch Layer</span>
                    <YesNoDropdown
                      name="mulch_layer"
                      value={formik.values.mulch_layer}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Transplanter</span>
                    <YesNoDropdown
                      name="transplanter"
                      value={formik.values.transplanter}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Vehicle To Flatbed Trailer</span>
                    <YesNoDropdown
                      name="vehicle_to_flatbed_trailer"
                      value={formik.values.vehicle_to_flatbed_trailer}
                      onChange={(name, value) =>
                        formik.setFieldValue(name, value)
                      }
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Type Of Truck</span>
                    <input
                      type="text"
                      name="type_of_truck"
                      value={formik.values.type_of_truck}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12">
                  <div className="position-relative">
                    <span className="field">
                      How unload Equipment From Trailer
                    </span>
                    <input
                      type="text"
                      name="how_unload_equipment_from_trailer"
                      value={formik.values.how_unload_equipment_from_trailer}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12">
                  <div className="position-relative">
                    <span className="field">Comments</span>
                    <textarea
                      name="comments"
                      value={formik.values.comments}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    ></textarea>
                  </div>
                </li>
              </ul>
              <div className="pt-5 text-end">
                <NavLink
                  to={{
                    pathname: "/farms-profile/view/" + FarmId,
                  }}
                  title="View"
                >
                  <button type="button" className="formBtn cancel">
                    Cancel
                  </button>
                </NavLink>
                <button type="submit" className="ms-3 formBtn">
                  Save Details
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FarmsProfileAdd;
