import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { GetClientsDetails } from "../../redux/actions/farmsAction";
import { FarmDetails } from "../../Typings/dataListing";
import { FormikProps } from "formik/dist/types";
import { useFormik } from "formik/dist/Formik";
import { object } from "yup";
import { hasPermission } from "../../config/permissionUtils";
import { usePermissions } from "../../context/PermissionsContext";
const FarmsView: FC = () => {
  const roleid = localStorage.getItem("roleid") ?? "";
  const { permissions, loading, error } = usePermissions();
  const dispatch = useDispatch<AppDispatch>();
  const [Farmlist, setFarmList] = useState<FarmDetails | null>(null); // Type-safe state
  const { FarmId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getFarmData();
  }, [dispatch]);

  const getFarmData = async () => {
    const payload = { farm_Id: FarmId };
    try {
      dispatch(
        GetClientsDetails(payload, (response: any) => {
          const farmData = response?.data;
          if (farmData) {
            setFarmList(farmData);
          }
        })
      );
    } catch (error) {
      console.error("Error fetching farm details:", error);
    }
  };

  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">
                {Farmlist?.farm_name || "Farm Name"}
              </span>
              {Farmlist?.farm_id || "N/A"}
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
            <div className="subHd mb-3">Farm Information</div>
            <ul className="d-flex flex-wrap p-0 viewList">
              <li className="col-6 col-md-4 col-lg-3">
                First Name{" "}
                <span className="d-block pt-1">
                  {Farmlist?.first_name || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Last Name{" "}
                <span className="d-block pt-1">
                  {Farmlist?.last_name || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Mobile{" "}
                <span className="d-block pt-1">
                  {Farmlist?.mobile_phone_number || "N/A"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Work Phone{" "}
                <span className="d-block pt-1">
                  {Farmlist?.work_phone_number || "N/A"}
                </span>
              </li>
              <li className="col-12 col-sm-6">
                Email Address 1{" "}
                <span className="d-block pt-1">
                  {Farmlist?.email_address_1 || "-"}
                </span>
              </li>
              <li className="col-12 col-sm-6">
                Email Address 2{" "}
                <span className="d-block pt-1">
                  {Farmlist?.email_address_2 || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Mailing Address Line 1{" "}
                <span className="d-block pt-1">
                  {Farmlist?.mailing_address_line_1 || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Mailing Address Line 2{" "}
                <span className="d-block pt-1">
                  {Farmlist?.mailing_address_line_2 || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                City{" "}
                <span className="d-block pt-1">
                  {Farmlist?.mailing_address_city || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                State/ Zip Code{" "}
                <span className="d-block pt-1">
                  {Farmlist?.mailing_address_state || "-"},{" "}
                  {Farmlist?.mailing_address_zip_postal_code || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                USDA Number{" "}
                <span className="d-block pt-1">
                  {Farmlist?.usda_fsa_num || "N/A"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Farm Name{" "}
                <span className="d-block pt-1">
                  {Farmlist?.farm_name || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                County{" "}
                <span className="d-block pt-1">{Farmlist?.county || "-"}</span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Field Day{" "}
                <span className="d-block pt-1">
                  {Farmlist?.field_day || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Farm Address Line 1{" "}
                <span className="d-block pt-1">
                  {Farmlist?.farm_address_line_1 || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Farm Address Line 2{" "}
                <span className="d-block pt-1">
                  {Farmlist?.farm_address_line_2 || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Farm City{" "}
                <span className="d-block pt-1">
                  {Farmlist?.farm_address_city || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Farm State / Zip Code{" "}
                <span className="d-block pt-1">
                  {Farmlist?.farm_address_state || "-"},{" "}
                  {Farmlist?.farm_address_zip_postal_code || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Latitude{" "}
                <span className="d-block pt-1">
                  {Farmlist?.latitude || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Longitude{" "}
                <span className="d-block pt-1">
                  {Farmlist?.longitude || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Status{" "}
                <span className="d-block pt-1">{Farmlist?.status || "-"}</span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Reason{" "}
                <span className="d-block pt-1">
                  {Farmlist?.ineligible_reason || "N/A"}
                </span>
              </li>
              <li className="col-12">
                Notes{" "}
                <span className="d-block pt-1">{Farmlist?.notes || "-"}</span>
              </li>
            </ul>
            <div className="pt-5 text-end">
              <NavLink to="/farms">
                <button type="button" className="formBtn cancel">
                  Cancel
                </button>
              </NavLink>
              {hasPermission(permissions, roleid, "Farm", "edit") && (
                <NavLink
                  to={{
                    pathname: "/farms/edit/" + Farmlist?.farm_id,
                  }}
                  title="View"
                >
                  <button type="button" className="ms-3 formBtn">
                    Edit Details
                  </button>
                </NavLink>
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FarmsView;
