import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
const Partner: FC = () => {
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Partner Activity</span>
            </div>
            <div className="bradcrumb">
            <NavLink  to="/dashboard">Dashboard</NavLink> &gt; Partners
            </div>
          </div>
        </section>
        <section className="filterCon">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center pt-3 pb-3">
              <div className="searchBy">Search By</div>
              <ul className="d-flex flex-wrap filterBox">
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Partner ID</span>
                    <input
                      type="text"
                      className="filterInput"
                      placeholder="1002"
                    />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Partner Name</span>
                    <input type="text" className="filterInput" />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <select className="filterSelect">
                      <option>Partner Type</option>
                    </select>
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <select className="filterSelect">
                      <option>Partner Poc</option>
                    </select>
                  </div>
                </li>
                <li className="btn"><button type="button"><span className="material-icons">search</span></button></li>
              </ul>
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 mt-2 whtBg">
          <div className="container">
            <div className="tableBox">
              <table>
                <tr>
                  <th>Partner ID</th>
                  <th>Partner Name</th>
                  <th>Partner Type</th>
                  <th>Partner POC</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>1001</td>
                  <td>Carolina Farm Stewardship</td>
                  <td>Non Profit</td>
                  <td>Naturally Grown</td>
                  <td>Jan 10 2024</td>
                  <td>Jan 09 2025</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/partner/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/partner/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1002</td>
                  <td>Clemson University</td>
                  <td>NC</td>
                  <td>Certified Organic</td>
                  <td>Jan 12 2024</td>
                  <td>Jan 11 2025</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/partner/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/partner/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1003</td>
                  <td>Emory University</td>
                  <td>SC</td>
                  <td>Naturally Grown</td>
                  <td>Feb 05 2024</td>
                  <td>Fev 04 2025</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/partner/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/partner/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1004</td>
                  <td>Georgia Organics</td>
                  <td>GA</td>
                  <td>Conventional</td>
                  <td>Feb 15 2024</td>
                  <td>Feb 14 2025</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/partner/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/partner/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1005</td>
                  <td>NS State University</td>
                  <td>GA</td>
                  <td>Naturally Grown</td>
                  <td>Feb 20 2024</td>
                  <td>Feb 19 2025</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/partner/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/partner/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1006</td>
                  <td>NCAAS University</td>
                  <td>NC</td>
                  <td>Conventional</td>
                  <td>Mar 02 2024</td>
                  <td>MAr 01 2025</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/partner/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/partner/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1007</td>
                  <td>Poket</td>
                  <td>NC</td>
                  <td>Certified Naturally Grown</td>
                  <td>Mar 20 2024</td>
                  <td>Mar 19 2025</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/partner/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/partner/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1008</td>
                  <td>Rodale Institute</td>
                  <td>NC</td>
                  <td>Certified Organic</td>
                  <td>Apr 03 2024</td>
                  <td>Apr 02 2025</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/partner/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/partner/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1009</td>
                  <td>Soil Health</td>
                  <td>SC</td>
                  <td>Conventional</td>
                  <td>Apr 06 2024</td>
                  <td>Apr 05 2025</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/partner/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/partner/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1010</td>
                  <td>Wisconsin</td>
                  <td>GA</td>
                  <td>Naturally Grown</td>
                  <td>Apr 14 2024</td>
                  <td>Apr 13 2025</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/partner/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/partner/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
              <div className="totalResul">Result found (15)</div>
              <div className="paging">
                <a href="#">&lt;&lt;</a>
                <a href="#">&lt;</a>
                <a href="#" className="number">1</a>
                <a href="#">&gt;</a>
                <a href="#">&gt;&gt;</a>
              </div>
              <div></div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Partner;
