import { string } from "yup";
import API from "../../config/api";
import { serializeQueryParams } from "../../config/apiutlils";
import config from "../../config/config";
import { Success } from "../../context/notify";
import { BlogDetailsParams, BlogParams } from "../../Typings/dataListing";
import { dataActionTypes } from "../constants/ActionTypes";

export const getFarmProfileList = (defaultparams: any,callBack?: any) => async (dispatch: any) => {
    let path = "api/FarmProfile/GetFarmProfileList";
    await API().get(path,{
        params: defaultparams,
    }).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => { 
        callBack(err); 
    });
}
export const GetFarmProfileById = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = "api/FarmProfile/GetById"
    await API().get(path, {
        params: payload,
    }).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};

export const UpdateFarmProfileById = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    var path = "api/FarmProfile/UpdateFarmProfile";
    await API().patch(path, defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => {
        callBack(err);
    });
}

export const AddFarmProfileData = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    var path = "api/FarmProfile/AddFarmProfile";
    await API().post(path, defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => {
        callBack(err);
    });
}

