import { string } from "yup";
import API from "../../config/api";
import { serializeQueryParams } from "../../config/apiutlils";
import config from "../../config/config";
import { Success } from "../../context/notify";
import { BlogDetailsParams, BlogParams } from "../../Typings/dataListing";
import { dataActionTypes } from "../constants/ActionTypes";

export const getallfarmsList = (defaultparams: any,callBack?: any) => async (dispatch: any) => {
    let path = "api/Farms/GetFarmList";
    // path += serializeQueryParams(defaultparams);
    await API().get(path,{
        params: defaultparams,
    }).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => { 
        callBack(err); 
    });
}

export const GetClientsDetails = (payload: any, handleNext?: any) => async (dispatch: any) => {
    var path = "api/Farms/GetById"
    await API().get(path, {
        params: payload,
    }).then((response: any) => {
        if (response.status === 200 && handleNext) {
            handleNext(response.data);
        }
    }).catch((err) => {
        handleNext(err);
    });

};

export const UpdateFarm = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    var path = "api/Farms/UpdateFarm";
    await API().patch(path, defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => {
        callBack(err);
    });
}
export const AddFarmData = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    var path = "api/Farms/AddFarm";
    await API().post(path, defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => {
        callBack(err);
    });
}

export const GetFarmDetails = (defaultparams: any,callBack?: any) => async (dispatch: any) => {
    await API().get('api/Farms/GetById', defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            callBack(response.data);
        }
    }).catch((err) => { 
        callBack(err); 
    });
}