import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import { bool, number } from "yup";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { getFarmProfileList } from "../../redux/actions/farmProfileAction";

import Pagination from "../../components/pagination";
import { NULL } from "sass";
import {
  fetchStateData,
  fetchStatusData,
} from "../../redux/actions/dataActions";
import DropDown from "../../components/DropDown";
import { usePermissions } from "../../context/PermissionsContext";
import { hasPermission } from "../../config/permissionUtils";

const FarmsProfile: FC = () => {
  const roleid = localStorage.getItem("roleid") ?? "";
  const { permissions, loading, error } = usePermissions();
  const [CurrentPage, SetCurrentPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const [farm_name, setfarm_name] = useState("");
  const [pgsize, Setpgsize] = useState(10);
  const [FSA, setFSA] = useState("");
  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [statusOptions, setstatusOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [farm_address_state, setfarm_address_state] = useState<string>("");
  const [status, setstatus] = useState<string>("");
  const [TotalRecords, setTotalRecords] = useState();
  const [FarmProfileList, SetFarmProfileList] = useState({
    data: [],
    pageIndex: number,
    pageSize: number,
    totalrecords: number,
    totalPage: number,
    hasPreviousPage: bool,
    hasNextPage: bool,
  });

  useEffect(() => {
    fetchOptions();
    fetchStatusOptions();
    searchFarm();
  }, [CurrentPage, status, FSA, farm_name, farm_address_state]);
  const searchFarm = () => {
    var payload = {
      PageIndex: CurrentPage,
      PageSize: pgsize,
      SkipRow: pgsize * (CurrentPage - 1),
      SortBy: null,
      SortOrder: "desc",
      uusda_fsa_num: FSA,
      farm_id: farm_name,
      farm_address_state: farm_address_state,
      status: status,
    };
    dispatch(
      getFarmProfileList(payload, (response: any) => {
        if (response.status != 500) {
          SetFarmProfileList(response);
          setTotalRecords(response.totalrecords);
        }
      })
    );
  };

  const fetchOptions = async () => {
    const payload = {
      stateid: null,
      statename: null,
    };
    dispatch(
      fetchStateData(payload, (response: any) => {
        if (response && response.data && Array.isArray(response.data)) {
          const formattedOptions = response.data.map((item: any) => ({
            label: item.statename,
            value: item.statename.toString(),
          }));
          setOptions(formattedOptions);
        }
      })
    );
  };
  const fetchStatusOptions = async () => {
    const payload = {
      statusid: null,
      statusname: null,
    };
    dispatch(
      fetchStatusData(payload, (response: any) => {
        if (response && response.data && Array.isArray(response.data)) {
          const formattedOptions = response.data.map((item: any) => ({
            label: item.statusname,
            value: item.statusname.toString(),
          }));
          setstatusOptions(formattedOptions);
        }
      })
    );
  };

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4">
          <div className="container">
            <div className="d-flex justify-content-between align-items-end">
              <div>
                <div className="loginHd mb-1">
                  <span className="d-block">Farms Profile</span>
                </div>
                <div className="bradcrumb">
                  <NavLink to="/dashboard">Dashboard</NavLink> &gt; Farms
                  Profile
                </div>
              </div>
              <div>
                {hasPermission(permissions, roleid, "Farm Profile", "add") && (
                  <a href="/farms-profile/add" className="linkBtn">
                    Add Farm Profile
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="filterCon">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center pt-3 pb-3">
              <div className="searchBy">Search By</div>
              <ul className="d-flex flex-wrap filterBox">
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Farm ID</span>
                    <input
                      type="text"
                      className="filterInput"
                      placeholder="VA_O_2"
                      onChange={(event) => setfarm_name(event.target.value)}
                    />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">USDA/FSA</span>
                    <input
                      type="text"
                      className="filterInput"
                      onChange={(event) => setFSA(event.target.value)}
                    />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">State</span>
                    <DropDown
                      options={options}
                      onChange={(event) =>
                        setfarm_address_state(event.target.value)
                      }
                      className="filterInput"
                      Title="Select State"
                    />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Status</span>
                    <DropDown
                      options={statusOptions}
                      onChange={(event) => setstatus(event.target.value)}
                      className="filterInput"
                      Title="Select Status"
                    />
                  </div>
                </li>
                <li className="btn">
                  <button type="button">
                    <span className="material-icons">search</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 mt-2 whtBg">
          <div className="container">
            <div className="tableBox">
              <table>
                <thead></thead>
                <tr>
                  <th>Farm ID</th>
                  <th>USDA / FSA</th>
                  <th>Farm Name</th>
                  <th>Farm State</th>
                  <th>Type of Operation</th>
                  <th>Planting Zone</th>
                  <th>Action</th>
                </tr>
                {FarmProfileList.data && (
                  <tbody>
                    {FarmProfileList.data.map((item: any) => (
                      <tr key={"farm_id" + item.farm_id}>
                        <td>{item.farm_id}</td>
                        <td>{item.usda_fsa_num}</td>
                        <td>{item.farm_name}</td>
                        <td>{item.farm_address_state}</td>
                        <td>{item.type_of_operation}</td>
                        <td>{item.planting_zone}</td>
                        <td>
                          <div className="actionbtn d-flex">
                            {hasPermission(
                              permissions,
                              roleid,
                              "Farm Profile",
                              "view"
                            ) && (
                              <NavLink
                                to={{
                                  pathname:
                                    "/farms-profile/view/" + item.farm_id,
                                }}
                                title="View"
                              >
                                <span className="material-icons-outlined">
                                  visibility
                                </span>
                              </NavLink>
                            )}
                            {hasPermission(
                              permissions,
                              roleid,
                              "Farm Profile",
                              "edit"
                            ) && (
                              <NavLink
                                to={{
                                  pathname:
                                    "/farms-profile/edit/" + item.farm_id,
                                }}
                                title="Edit"
                              >
                                <span className="material-icons">edit</span>
                              </NavLink>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
              <div className="totalResul">{TotalRecords}</div>
              <Pagination
                GoToPage={(value: number) => {
                  SetCurrentPage(value);
                }}
                PageIndex={Number(FarmProfileList.pageIndex)}
                TotalPages={Number(FarmProfileList.totalPage)}
                hasNextPage={Boolean(FarmProfileList.hasNextPage)}
                hasPreviousPage={Boolean(FarmProfileList.hasPreviousPage)}
              />
              <div></div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FarmsProfile;
