import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { FarmDetails, FarmProfileDetails } from "../../Typings/dataListing";
import { GetFarmProfileById } from "../../redux/actions/farmProfileAction";
import { usePermissions } from "../../context/PermissionsContext";
import { hasPermission } from "../../config/permissionUtils";

const FarmsProfileView: FC = () => {
  const roleid = localStorage.getItem("roleid") ?? "";
  const { permissions, loading, error } = usePermissions();
  const dispatch = useDispatch<AppDispatch>();
  const [Farmlist, setFarmList] = useState<FarmProfileDetails | null>(null);
  const { FarmId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getFarmData();
  }, [dispatch]);

  const getFarmData = async () => {
    const payload = { farm_Id: FarmId };
    try {
      dispatch(
        GetFarmProfileById(payload, (response: any) => {
          const farmData = response?.data;
          if (farmData) {
            setFarmList(farmData);
            console.log(farmData);
          }
        })
      );
    } catch (error) {
      console.error("Error fetching farm details:", error);
    }
  };

  const CheckYesOrNo = (value: boolean | undefined): string => {
    return value === undefined ? "No" : value ? "Yes" : "No";
  };

  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">
                {Farmlist?.farm_name || "Farm Name"}
              </span>
              {Farmlist?.farm_id || "N/A"}
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
            <ul className="d-flex flex-wrap p-0 viewList">
              <li className="col-6 col-md-4 col-lg-3">
                Farm ID{" "}
                <span className="d-block pt-1">{Farmlist?.farm_id || "-"}</span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                USDA / FSA{" "}
                <span className="d-block pt-1">
                  {Farmlist?.usda_fsa_num || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Acreage{" "}
                <span className="d-block pt-1">{Farmlist?.acreage || "-"}</span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Planting Zone{" "}
                <span className="d-block pt-1">
                  {Farmlist?.planting_zone || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Tract{" "}
                <span className="d-block pt-1">{Farmlist?.tract || "-"}</span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Field Numbers{" "}
                <span className="d-block pt-1">
                  {Farmlist?.field_numbers || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Commodities Grown{" "}
                <span className="d-block pt-1">
                  {Farmlist?.commodities_grown || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Type Of Operation{" "}
                <span className="d-block pt-1">
                  {Farmlist?.type_of_operation || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Years In Production{" "}
                <span className="d-block pt-1">
                  {Farmlist?.years_in_production || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Beginning Farmer{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.beginning_farmer)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Limited Resource Farmer{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.limited_resource_farmer)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Duty Service Member (Veteran / Active){" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(
                    Farmlist?.veteran_or_active_duty_service_member
                  )}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Race / Ethnicity{" "}
                <span className="d-block pt-1">
                  {Farmlist?.race_ethnicity || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Self Describe Race Ethnicity{" "}
                <span className="d-block pt-1">
                  {Farmlist?.self_describe_race_ethnicity || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Gender{" "}
                <span className="d-block pt-1">{Farmlist?.gender || "-"}</span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Self Describe Gender{" "}
                <span className="d-block pt-1">
                  {Farmlist?.self_describe_gender || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Use Cover Crops{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.use_cover_crops)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Enrolled In Other Nrcs Program{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.enrolled_in_other_nrcs_program)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Other Nrcs Programs{" "}
                <span className="d-block pt-1">
                  {Farmlist?.other_nrcs_programs || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Willing To Dedicate 10 Acres{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.willing_to_dedicate_10_acres)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Reason Not Willing Dedicate 10 Acres{" "}
                <span className="d-block pt-1">
                  {Farmlist?.reason_for_not_willing_to_dedicate_10_acres || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Willing Commit Enrolled Acreage (Spring 2028){" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(
                    Farmlist?.willing_to_commit_enrolled_acreage_until_spring_2028
                  )}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Reason Not Willing Commit Enrolled Acreage (Spring 2028){" "}
                <span className="d-block pt-1">
                  {Farmlist?.reason_not_willing_to_commit_enrolled_acreage_until_spring_2028 ||
                    "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Willing Provide Periodic Economic Data{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(
                    Farmlist?.willing_to_provide_periodic_economic_data
                  )}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Reason Not Willing Provide Periodic Economic Data{" "}
                <span className="d-block pt-1">
                  {Farmlist?.reason_for_not_willing_to_provide_periodic_economic_data ||
                    "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Willing Allow Scheduled Visits{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.willing_to_allow_scheduled_visits)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Nrcs 1270{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.nrcs_1270)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                W_9{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.w_9)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                FSA Waiver{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.fsa_waiver)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Equipment Survey{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.equipment_survey)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Cash Crop Survey{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.cash_crop_survey)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Enrolled Acreage{" "}
                <span className="d-block pt-1">
                  {Farmlist?.enrolled_acreage || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Equipment Type{" "}
                <span className="d-block pt-1">
                  {Farmlist?.equipment_type || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Tractor hp{" "}
                <span className="d-block pt-1">
                  {Farmlist?.tractor_hp || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                3 pt Hitch Category{" "}
                <span className="d-block pt-1">
                  {Farmlist?.three_pt_hitch_category || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Mower Bush Hog{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.mower_bush_hog)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Tiller{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.tiller)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Tractor Hydraulic{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.tractor_hydraulic)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Tractor Electric{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.tractor_electric)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Seed Drill{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.seed_drill)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Mulch Layer{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.mulch_layer)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Transplanter{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.transplanter)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Vehicle To Flatbed Trailer{" "}
                <span className="d-block pt-1">
                  {CheckYesOrNo(Farmlist?.vehicle_to_flatbed_trailer)}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                Type Of Truck{" "}
                <span className="d-block pt-1">
                  {Farmlist?.type_of_truck || "-"}
                </span>
              </li>
              <li className="col-6 col-md-4 col-lg-3">
                How unload Equipment From Trailer{" "}
                <span className="d-block pt-1">
                  {Farmlist?.how_unload_equipment_from_trailer || "-"}
                </span>
              </li>
              <li className="col-12">
                Comments{" "}
                <span className="d-block pt-1">
                  {Farmlist?.comments || "-"}
                </span>
              </li>
            </ul>
            <div className="pt-5 text-end">
              <NavLink to="/farms-profile">
                <button type="button" className="formBtn cancel">
                  Cancel
                </button>
              </NavLink>
              {hasPermission(permissions, roleid, "Farm Profile", "edit") && (
                <NavLink
                  to={{
                    pathname: "/farms-profile/edit/" + Farmlist?.farm_id,
                  }}
                  title="Edit"
                >
                  <button type="button" className="ms-3 formBtn">
                    Edit Details
                  </button>
                </NavLink>
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FarmsProfileView;
