import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import * as Yup from "yup";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { string, object, date, ref } from "yup";
import { AddFarmData } from "../../redux/actions/farmsAction";
import { FormikProps, useFormik } from "formik";
import { FarmDetails } from "../../Typings/dataListing";
import { errorMsgs } from "../../context/notify";
import formatPhoneNumber from "../../components/formatPhoneNumber";
import { fetchStatusData } from "../../redux/actions/dataActions";
import DropDownForm from "../../components/DropDownForm";

const FarmsAdd: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { FarmId } = useParams();
  const navigate = useNavigate();
  const [statusOptions, setstatusOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [status, setstatus] = useState<string>("");

  const formik: FormikProps<FarmDetails> = useFormik({
    initialValues: {
      farm_id: "",
      usda_fsa_num: 0,
      first_name: "",
      last_name: "",
      farm_name: "",
      county: "",
      field_day: "",
      mailing_address_line_1: "",
      mailing_address_line_2: "",
      mailing_address_city: "",
      mailing_address_state: "",
      mailing_address_zip_postal_code: "",
      farm_address_line_1: "",
      farm_address_line_2: "",
      farm_address_city: "",
      farm_address_state: "",
      farm_address_zip_postal_code: "",
      latitude: 0,
      longitude: 0,
      mobile_phone_number: "",
      work_phone_number: "",
      email_address_1: "",
      email_address_2: "",
      status: "",
      ineligible_reason: "",
      notes: "",
    },
    validationSchema: object({
      email_address_1: Yup.string()
        .email("Invalid format for Email")
        .required("Email is required"),
      email_address_2: Yup.string()
        .email("Invalid format for Email")
        .required("Email is required"),
      farm_name: Yup.string().required("Farm name is required"),
      status: Yup.string()
        .required("Status is required")
        .oneOf(
          statusOptions.map((option) => option.value),
          "Invalid status selected"
        ),
    }),
    onSubmit: (values, { setSubmitting, setErrors }) => {
      console.log(values);
      dispatch(
        AddFarmData(values, (response: any) => {
          formik.resetForm();
          try {
            if (response.statusCode === 0) {
              // errorMsgs(response.message);
              navigate("/farms");
            } else if (response.statusCode === 1) {
              navigate("/farms");
            } else if (response.statusCode === 2) {
              navigate("/farms");
            }
            navigate("/farms");
          } catch (error) {
          } finally {
            navigate("/farms");
            // setSubmitting(false);
          }
        })
      );
    },
  });

  const fetchStatusOptions = async () => {
    const payload = {
      statusid: null,
      statusname: null,
    };
    dispatch(
      fetchStatusData(payload, (response: any) => {
        if (response && response.data && Array.isArray(response.data)) {
          const formattedOptions = response.data.map((item: any) => ({
            label: item.statusname,
            value: item.statusname.toString(),
          }));
          setstatusOptions(formattedOptions);
        }
      })
    );
  };

  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Add Farms Information</span>{" "}
              {formik.values.farm_id}
            </div>
          </div>
        </section>

        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
            <form onSubmit={formik.handleSubmit}>
              <div className="subHd mb-4">Farm Information</div>
              <ul className="d-flex flex-wrap p-0 viewList">
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Farm Id</span>
                    <input
                      type="text"
                      name="farm_id"
                      value={formik.values.farm_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">First Name</span>
                    <input
                      type="text"
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Last Name</span>
                    <input
                      type="text"
                      name="last_name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Mobile</span>
                    <input
                      type="text"
                      name="mobile_phone_number"
                      value={formik.values.mobile_phone_number}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "mobile_phone_number",
                          formatPhoneNumber(e.target.value)
                        );
                      }}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                      maxLength={12}
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Work Phone</span>
                    <input
                      type="text"
                      name="work_phone_number"
                      value={formik.values.work_phone_number}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "work_phone_number",
                          formatPhoneNumber(e.target.value)
                        );
                      }}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                      maxLength={12}
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Email Address 1</span>
                    <input
                      type="email"
                      name="email_address_1"
                      value={formik.values.email_address_1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                    {formik.touched.email_address_1 &&
                      formik.errors.email_address_1 && (
                        <span className="error">
                          {formik.errors.email_address_1}
                        </span>
                      )}
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Email Address 2</span>
                    <input
                      type="email"
                      name="email_address_2"
                      value={formik.values.email_address_2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                    {formik.touched.email_address_2 &&
                      formik.errors.email_address_2 && (
                        <span className="error">
                          {formik.errors.email_address_2}
                        </span>
                      )}
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Mailing Address Line 1</span>
                    <input
                      type="text"
                      name="mailing_address_line_1"
                      value={formik.values.mailing_address_line_1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Mailing Address Line 2</span>
                    <input
                      type="text"
                      name="mailing_address_line_2"
                      value={formik.values.mailing_address_line_2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">City</span>
                    <input
                      type="text"
                      name="mailing_address_city"
                      value={formik.values.mailing_address_city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">State</span>
                    <input
                      type="text"
                      name="mailing_address_state"
                      value={formik.values.mailing_address_state}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "mailing_address_state",
                          e.target.value.toUpperCase()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                      maxLength={2}
                      pattern="[A-Z]{2}"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">Zip Code</span>
                    <input
                      type="text"
                      name="mailing_address_zip_postal_code"
                      value={formik.values.mailing_address_zip_postal_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                      pattern="^\d{5}(-\d{4})?$"
                      maxLength={9}
                      title="Enter a valid zip code: 12345 or 12345-6789"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">USDA Number</span>
                    <input
                      type="number"
                      name="usda_fsa_num"
                      value={formik.values.usda_fsa_num}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Farm Name</span>
                    <input
                      type="text"
                      name="farm_name"
                      value={formik.values.farm_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                    {formik.touched.farm_name && formik.errors.farm_name && (
                      <span className="error">{formik.errors.farm_name}</span>
                    )}
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">County</span>
                    <input
                      type="text"
                      name="county"
                      value={formik.values.county}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Field Day</span>
                    <input
                      type="text"
                      name="field_day"
                      value={formik.values.field_day}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Farm Address Line 1</span>
                    <input
                      type="text"
                      name="farm_address_line_1"
                      value={formik.values.farm_address_line_1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Farm Address Line 2</span>
                    <input
                      type="text"
                      name="farm_address_line_2"
                      value={formik.values.farm_address_line_2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">Farm City</span>
                    <input
                      type="text"
                      name="farm_address_city"
                      value={formik.values.farm_address_city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">Farm State</span>
                    <input
                      type="text"
                      name="farm_address_state"
                      value={formik.values.farm_address_state}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "farm_address_state",
                          e.target.value.toUpperCase()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                      maxLength={2}
                      pattern="[A-Z]{2}"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">Farm Zip Code</span>
                    <input
                      type="text"
                      name="farm_address_zip_postal_code"
                      value={formik.values.farm_address_zip_postal_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Latitude</span>
                    <input
                      type="text"
                      name="latitude"
                      value={formik.values.latitude}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Longitude</span>
                    <input
                      type="text"
                      name="longitude"
                      value={formik.values.longitude}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Status</span>
                    <DropDownForm
                      options={statusOptions}
                      value={formik.values.status}
                      onChange={(event) =>
                        formik.setFieldValue("status", event.target.value)
                      }
                      className="filterInput"
                      Title="Select Status"
                    />
                    {formik.touched.status && formik.errors.status && (
                      <div className="error">{formik.errors.status}</div>
                    )}
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Reason</span>
                    <input
                      type="text"
                      name="ineligible_reason"
                      value={formik.values.ineligible_reason}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12">
                  <div className="position-relative">
                    <span className="field">Notes</span>
                    <input
                      type="text"
                      name="notes"
                      value={formik.values.notes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
              </ul>
              <div className="pt-5 text-end">
                <NavLink to="/farms">
                  <button type="button" className="formBtn cancel">
                    Cancel
                  </button>
                </NavLink>
                <button type="submit" className="ms-3 formBtn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FarmsAdd;
