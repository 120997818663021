import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
const ProjectGranteeView: FC = () => {
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Project Grantee Profile</span> Name/ID of Project Grantee
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
           <ul className="d-flex flex-wrap p-0 viewList">
            <li className="col-6 col-md-4 col-lg-3">Partner Title <span className="d-block pt-1">Char</span></li>
            <li className="col-6 col-md-4 col-lg-3">Project ID <span className="d-block pt-1">Num</span></li>
            <li className="col-6 col-md-4 col-lg-3">Grantee Organization Name <span className="d-block pt-1">Char</span></li>
            <li className="col-6 col-md-4 col-lg-3">Grantees Primary Point of Contact <span className="d-block pt-1">Num</span></li>
            <li className="col-6 col-md-4 col-lg-3">Grantees Primary Point of Contact Email <span className="d-block pt-1">exmple@email.com</span></li>
            <li className="col-6 col-md-4 col-lg-3">Calendar Year of Report Submission  <span className="d-block pt-1">Char</span></li>
            <li className="col-6 col-md-4 col-lg-3">Reporting Period <span className="d-block pt-1">Char</span></li>
            <li className="col-6 col-md-4 col-lg-3">Project Start Date <span className="d-block pt-1">mm-dd-yy</span></li>
            <li className="col-6 col-md-4 col-lg-3">Project End Date <span className="d-block pt-1">mm-dd-yy</span></li>
            <li className="col-6 col-md-4 col-lg-3">Total Approved Budget <span className="d-block pt-1">num</span></li>
            <li className="col-6 col-md-4 col-lg-3">Project Description  <span className="d-block pt-1">Char</span></li>
            <li className="col-6 col-md-4 col-lg-3">Comet Planner Version <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Total Producers Enrolled <span className="d-block pt-1">num</span></li>
            <li className="col-6 col-md-4 col-lg-3">Total No. Acres Enrolled <span className="d-block pt-1">num</span></li>
            <li className="col-6 col-md-4 col-lg-3">Total No. Livestock Enrolled <span className="d-block pt-1">num</span></li>
           </ul>
           <div className="pt-5 text-end">
            <NavLink  to="/project-grantee"><button type="button" className="formBtn cancel">Cancel</button></NavLink>
            <NavLink  to="/project-grantee/edit"><button type="button" className="ms-3 formBtn">Edit Details</button></NavLink>
           </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectGranteeView;
