interface DropDownProps {
  options: Array<{ label: string; value: string }>;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  Title?: string;
  value?: string; // Add value prop for controlled behavior
}

const DropDownForm: React.FC<DropDownProps> = ({
  options,
  className,
  onChange,
  Title,
  value, // Accept the value as a prop
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <select
      className={className}
      onChange={handleChange}
      value={value || ""} 
    >
      <option value="">{Title}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default DropDownForm;
