import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
const ProducersView: FC = () => {
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Producer Enrollment</span> Producer's Name
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
           <ul className="d-flex flex-wrap p-0 viewList">
            <li className="col-6 col-md-4 col-lg-3">State/Territory <span className="d-block pt-1">Char</span></li>
            <li className="col-6 col-md-4 col-lg-3">County <span className="d-block pt-1">Num</span></li>
            <li className="col-6 col-md-4 col-lg-3">Producer Name <span className="d-block pt-1">Char</span></li>
            <li className="col-6 col-md-4 col-lg-3">Change Future Data for Previously Enrolled <span className="d-block pt-1">Num</span></li>
            <li className="col-6 col-md-4 col-lg-3">Producer Start Date <span className="d-block pt-1">mm-dd-yy</span></li>
            <li className="col-6 col-md-4 col-lg-3">Organic Farm <span className="d-block pt-1">char</span></li>
            <li className="col-6 col-md-4 col-lg-3">Organic Field <span className="d-block pt-1">Char</span></li>
            <li className="col-6 col-md-4 col-lg-3">CSAF Experience <span className="d-block pt-1">mm-dd-yy</span></li>
            <li className="col-6 col-md-4 col-lg-3">Total Forest Area <span className="d-block pt-1">num</span></li>
            <li className="col-6 col-md-4 col-lg-3">Total Livestock Area <span className="d-block pt-1">num</span></li>
            <li className="col-6 col-md-4 col-lg-3">Livestock Type <span className="d-block pt-1">Char</span></li>
            <li className="col-6 col-md-4 col-lg-3">Livestock Head <span className="d-block pt-1">Yes</span></li>
           </ul>
           <div className="pt-5 text-end">
            <NavLink  to="/producers"><button type="button" className="formBtn cancel">Cancel</button></NavLink>
            <NavLink  to="/producers/edit"><button type="button" className="ms-3 formBtn">Edit Details</button></NavLink>
           </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ProducersView;
