import { Routes, Route } from "react-router-dom";
import Login from "../pages/accounts/Login";
import Dashboard from "../pages/accounts/dashboard";
import Farms from "../pages/farms/farms";
import FarmsView from "../pages/farms/farms-view";
import FarmsEdit from "../pages/farms/farms-edit";
import FarmAdd from "../pages/farms/farms-Add";
import FarmsProfile from "../pages/farms-profile/farms-profile";
import FarmsProfileView from "../pages/farms-profile/farms-profile-view";
import FarmsProfileEdit from "../pages/farms-profile/farms-profile-edit";
import ProjectGrantee from "../pages/project-grantee/project-grantee";
import ProjectGranteeView from "../pages/project-grantee/project-grantee-view";
import ProjectGranteeEdit from "../pages/project-grantee/project-grantee-edit";
import Producers from "../pages/producers/producers";
import ProducersEdit from "../pages/producers/producers-edit";
import ProducersView from "../pages/producers/producers-view";
import Partner from "../pages/partner/partner";
import PartnerView from "../pages/partner/partner-view";
import PartnerEdit from "../pages/partner/partner-edit";
import FarmsProfileAdd from "../pages/farms-profile/farms-profile-add";

export default function Root() {
  return (
    <>
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="farms" element={<Farms />} />
        <Route path="farms/view" element={<FarmsView />} />
        <Route path="farms/edit" element={<FarmsEdit />} />
        <Route path="farms/add" element={<FarmAdd />} />
        <Route path="farms-profile" element={<FarmsProfile />} />
        <Route path="farms-profile/view" element={<FarmsProfileView />} />
        <Route path="farms-profile/edit" element={<FarmsProfileEdit />} />
        <Route path="farms-profile/add" element={<FarmsProfileAdd />} />
        <Route path="farms/edit/:FarmId/*" element={<FarmsEdit />} />
        <Route path="farms/view/:FarmId/*" element={<FarmsView />} />
        <Route path="farms-profile/view/:FarmId/*" element={<FarmsProfileView />} />
        <Route path="farms-profile/edit/:FarmId/*" element={<FarmsProfileEdit />} />

        <Route path="project-grantee" element={<ProjectGrantee />} />
        <Route path="project-grantee/view" element={<ProjectGranteeView />} />
        <Route path="project-grantee/edit" element={<ProjectGranteeEdit />} />
        <Route path="producers" element={<Producers />} />
        <Route path="producers/view" element={<ProducersView />} />
        <Route path="producers/edit" element={<ProducersEdit />} />

        <Route path="partner" element={<Partner />} />
        <Route path="partner/view" element={<PartnerView />} />
        <Route path="partner/edit" element={<PartnerEdit />} />
      </Routes>
    </>
  );
}
