export const plantingZoneOptions = [
    { label: "6a", value: "6a" },
    { label: "6b", value: "6b" },
    { label: "7a", value: "7a" },
    { label: "7b", value: "7b" },
    { label: "8a", value: "8a" },
    { label: "8b", value: "8b" },
];

export const raceEthnicityOptions = [
    { label: "African American or Black", value: "African American or Black" },
    { label: "American Indian or Alaska Native", value: "American Indian or Alaska Native" },
    { label: "Asian or Asian American", value: "Asian or Asian American" },
    { label: "Hispanic, Latinx, or Spanish Origin", value: "Hispanic, Latinx, or Spanish Origin" },
    { label: "Middle Eastern or North African", value: "Middle Eastern or North African" },
    { label: "Native Hawaiian or Pacific Islander", value: "Native Hawaiian or Pacific Islander" },
    { label: "Biracial/Multiracial", value: "Biracial/Multiracial" },
    { label: "White", value: "White" },
    { label: "Prefer not to answer", value: "Prefer not to answer" },
    { label: "Prefer to self describe", value: "Prefer to self describe" },
];

export const typeOfOperationOptions = [
    { label: "Conventional", value: "Conventional" },
    { label: "Certified Organic", value: "Certified Organic" },
    { label: "Naturally Grown", value: "Naturally Grown" },
    { label: "Certified Naturally Grown", value: "Certified Naturally Grown" },
];

export const genderOptions = [
    { label: "Female", value: "Female" },
    { label: "Male", value: "Male" },
    { label: "Non-binary", value: "Non-binary" },
    { label: "Prefer not to answer", value: "Prefer not to answer" },
    { label: "Prefer to self-describe", value: "Prefer to self-describe" },
];