interface DropDownProps {
  options: Array<{ label: string; value: string }>;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  Title?: string;
}

const DropDown: React.FC<DropDownProps> = ({
  options,
  className,
  onChange,
  Title,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <select className={className} onChange={handleChange}>
      <option value= "">{Title}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default DropDown;
